import WPhoto_RoundPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/RoundPhoto.skin';
import WPhoto_RoundPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_RoundPhoto = {
  component: WPhoto_RoundPhotoComponent,
  controller: WPhoto_RoundPhotoController
};


export const components = {
  ['WPhoto_RoundPhoto']: WPhoto_RoundPhoto
};

