export const TRANSPARENT_BASE64_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const TestIds = {
  expandButton: 'expandButton',
};

export const TranslationKeys = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  EXPAND_LABEL_KEY: 'WPhoto_AriaLabel_Expand',
  EXPAND_FALLBACK_LABEL: 'Expand',
};
